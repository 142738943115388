import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Box, Stack, VStack, SimpleGrid } from '@chakra-ui/react';
import ButtonLink from '../button-link';

import BlockQuote from '../block-quote';
import Html from '../html';
import Heading from '../heading';

const HomeHeroContent = ({
  heroTitle,
  heroImage,
  heroTextHtml,
  heroPrimaryCallToActionUrl,
  heroPrimaryCallToActionText,
  heroSecondaryCallToActionUrl,
  heroSecondaryCallToActionText,
  quotes = [],
}) => (
  <Box mt={8} mb={8}>
    {heroTitle && (
      <Heading as="h1" pb={3}>
        {heroTitle}
      </Heading>
    )}
    {heroImage && (
      <GatsbyImage image={getImage(heroImage)} alt={heroImage.title} />
    )}
    {heroTextHtml && <Html source={heroTextHtml} />}
    <VStack>
      {((heroPrimaryCallToActionUrl && heroPrimaryCallToActionText) ||
        (heroSecondaryCallToActionUrl && heroSecondaryCallToActionText)) && (
        <Stack
          mt={12}
          mb={12}
          alignItems="center"
          justifyContent="center"
          spacing={[4, 4, 8]}
          direction={['column', 'column', 'row']}
        >
          {heroPrimaryCallToActionUrl && heroPrimaryCallToActionText && (
            <ButtonLink
              to={heroPrimaryCallToActionUrl}
              colorScheme="orange"
              fontSize="xl"
            >
              {heroPrimaryCallToActionText}
            </ButtonLink>
          )}
          {heroSecondaryCallToActionUrl && heroSecondaryCallToActionText && (
            <ButtonLink
              to={heroSecondaryCallToActionUrl}
              fontSize="xl"
              variant="outline"
              colorScheme="orange"
            >
              {heroSecondaryCallToActionText}
            </ButtonLink>
          )}
        </Stack>
      )}
      <SimpleGrid columns={[1, 1, 2]} spacing={4}>
        {quotes.slice(0, 2).map((quote) => (
          <BlockQuote
            key={quote.id}
            text={quote.text}
            person={quote.person}
            position={quote.position}
            company={quote.company}
          />
        ))}
      </SimpleGrid>
    </VStack>
  </Box>
);

export default HomeHeroContent;
